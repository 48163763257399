<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-airplane</v-icon>
          AEROLINEAS
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn small color="green" dark @click="abrirDialogAerolinea(null)"
          ><v-icon left>mdi-plus</v-icon>Crear Aerolínea</v-btn
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="busqueda"
          @click:append="cargarLista()"
          @keyup.enter="cargarLista()"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          class="mt-2"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-2"
          v-model="incInac"
          label="Incluir inactivos"
          @keyup.enter="cargarLista()"
          @change="cargarLista()"
          single-line
        >
        </v-checkbox>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :options.sync="options"
        hide-default-footer
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn
            icon
            color="green"
            title="editar datos de aerolinea"
            @click="abrirDialogAerolinea(item)"
          >
            <v-icon left small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            icon
            color="red"
            title="borrar aerolinea"
            @click="borrarAerolinea(item.ENTE_ID)"
          >
            <v-icon left small>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOGO AEROLINEA  -->
    <v-dialog
      v-model="dialogAerolinea"
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-snackbar
          v-model="sbAerolinea"
          :timeout="timeout"
          :top="true"
          :absolute="true"
          color="#333333"
        >
          {{ mensajeSbAerolinea }}
          <v-btn color="green" text small @click="sbAerolinea = false">
            Cerrar
          </v-btn>
        </v-snackbar>
        <v-card height="auto">
          <v-toolbar height="50" fixed flat color="primary" dark>
            <v-btn icon dark @click="dialogAerolinea = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ formTitulo }}</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical v-model="tabAerolinea">
            <v-tab>
              <v-icon left> mdi-account </v-icon>
              Info General
            </v-tab>
            <v-tab>
              <v-icon left> mdi-contacts </v-icon>
              Contactos
            </v-tab>

            <v-tab>
              <v-icon left> mdi-file-arrow-up-down</v-icon>
              Envío de datos
            </v-tab>

            <v-tab-item>
              <v-form ref="formAerolinea">
                <v-card-text flat class="pt-2">
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-2 pb-0" cols="6">
                      <v-text-field
                        :rules="requiredRule"
                        label="Razón social "
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_NOMBRE"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="pt-1 pb-1" cols="6">
                      <v-text-field
                        :rules="requiredRule"
                        label="Nombre comercial "
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_NOM_COMER"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="RUC "
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_RUC"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="Representante Legal "
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_REPRE"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="12">
                      <v-text-field
                        label="Dirección "
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_DIR"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="Teléfono 1"
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_TEL"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="Teléfono 2"
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_TEL2"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="12">
                      <v-text-field
                        :rules="validarEmail1"
                        label="Email"
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_EMAIL1"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="Ciudad"
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_CIUDAD"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="Codigo postal"
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_COD_POSTAL"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="4">
                      <v-autocomplete
                        label="País"
                        clearable
                        :items="paises"
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_PAIS"
                        item-text="NOMBRE"
                        item-value="PAIS_CODIGO"
                        @change="setCodigoPais"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="2">
                      <v-text-field
                        readonly
                        label="Codigo pais"
                        :readonly="lectura"
                        v-model="datosAerolinea.ENTE_PAIS_COD"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
              <v-card-actions>
                <v-col class="text-center">
                  <v-btn color="primary" small @click="guardarAerolinea()">
                    <v-icon>mdi-content-save</v-icon> Guardar
                  </v-btn>
                  &nbsp;&nbsp;
                  <v-btn @click="dialogAerolinea = !dialogAerolinea" small>
                    <v-icon>mdi-cancel</v-icon> Cancelar
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-tab-item>
            <!--CONTACTOS-->
            <v-tab-item v-if="tabContactos">
              <v-card flat class="mx-10">
                <v-toolbar elevation="1">
                  <v-toolbar-title>Contactos</v-toolbar-title>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-btn
                    small
                    color="blue"
                    dark
                    @click="abrirDialogContacto(null)"
                    ><v-icon left>mdi-plus</v-icon>Agregar</v-btn
                  >
                </v-toolbar>
                <v-data-table
                  :headers="headersContactos"
                  :items="listaContactos"
                  :items-per-page="itemsPerPage"
                  :options.sync="options"
                  hide-default-footer
                  :loading="loadingTable"
                  class="elevation-1"
                >
                  <template v-slot:item.opcion="{ item }">
                    <v-btn
                      icon
                      title="editar contacto"
                      @click="abrirDialogContacto(item)"
                    >
                      <v-icon small color="green">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      title="borrar contacto"
                      @click="borrarContacto(item.CONTACTO_ID)"
                    >
                      <v-icon small color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <!-- TAB PROCESO-->
            <v-tab-item v-if="tabProc">
              <v-card>
                <v-toolbar elevation="1">
                  <v-toolbar-title>Envío de datos</v-toolbar-title>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-btn
                    small
                    color="blue"
                    dark
                    @click="abrirDialogProceso(null)"
                    ><v-icon left>mdi-plus</v-icon>Agregar</v-btn
                  >
                </v-toolbar>
                <v-data-table
                  :headers="headersProcesos"
                  :items="procesos"
                  :items-per-page="itemsPerPage"
                  :options.sync="options"
                  hide-default-footer
                  :loading="loadingTable"
                  class="elevation-1"
                >
                  <template v-slot:item.opcion="{ item }">
                    <v-btn
                      icon
                      color="green"
                      title="editar datos del proceso"
                      @click="abrirDialogProceso(item)"
                    >
                      <v-icon left small>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                      icon
                      color="red"
                      title="borrar datos del proceso"
                      @click="borrarProceso(item.ID)"
                    >
                      <v-icon left small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-card>
    </v-dialog>

    <!-- dialogo contacto -->
    <v-dialog v-model="dialogContacto" max-width="750px" persistent scrollable>
      <v-form ref="formContacto">
        <v-card>
          <v-toolbar flat color="blue" fixed height="40" dark>
            <v-toolbar-title>
              <v-icon class="mx-1">mdi-account</v-icon>
              Contacto
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogContacto = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-row class="pa-4">
              <v-col cols="6" class="py-0">
                <v-text-field
                  :rules="requiredRule"
                  class="mt-0"
                  label="Nombres"
                  v-model="datosContacto.NOMBRE"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Ocupación"
                  v-model="datosContacto.OCUPACION"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :rules="validarEmail1Contacto"
                  class="mt-0"
                  label="Email 1"
                  v-model="datosContacto.CORREO1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-text-field
                  :rules="validarEmail2Contacto"
                  class="mt-0"
                  label="Email 2"
                  v-model="datosContacto.CORREO2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-text-field
                  class="mt-0"
                  label="Telefono oficina"
                  v-model="datosContacto.TELEFONO1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Teléfono casa"
                  v-model="datosContacto.TELEFONO2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Celular"
                  v-model="datosContacto.CELULAR1"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Otro teléfono"
                  v-model="datosContacto.CELULAR2"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Dirección"
                  v-model="datosContacto.DIRECCION"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Ciudad"
                  v-model="datosContacto.CIUDAD"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="País"
                  v-model="datosContacto.PAIS"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-textarea
                  style="font-size: 0.85em"
                  rows="2"
                  class="mt-0"
                  label="Observaciones"
                  v-model="datosContacto.OBSERVACION"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-toolbar flat>
            <v-btn @click="guardarContacto()" small color="green" dark
              ><v-icon left>mdi-content-save</v-icon>Guardar</v-btn
            >
            <v-divider vertical class="px-4"></v-divider>
            <v-btn @click="dialogContacto = false" small text
              ><v-icon left>mdi-cancel</v-icon>Cancelar</v-btn
            >
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- DIALOGO PROCESO -->
    <v-dialog v-model="dialogProceso" max-width="750px" persistent scrollable>
      <v-form ref="formProceso">
        <v-card>
          <v-toolbar flat color="blue" fixed height="40" dark>
            <v-toolbar-title>
              <v-icon class="mx-1">mdi-file-arrow-up-down</v-icon>
              Envío de datos
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogProceso = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-row class="pa-4">
              <v-col cols="12" class="py-0">
                <v-text-field
                  :rules="requiredRule"
                  class="mt-0"
                  label="Nombre"
                  v-model="datosProceso.NOMBRE"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-select
                  :rules="requiredRule"
                  class="mt-0"
                  label="Metodo"
                  v-model="datosProceso.MANEJADOR"
                  :items="metodos"
                  item-text="nombre"
                  item-value="codigo"
                ></v-select>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-select
                  :rules="requiredRule"
                  class="mt-0"
                  label="Formato"
                  v-model="datosProceso.FORMATO"
                  :items="formatos"
                  item-text="nombre"
                  item-value="codigo"
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                class="py-0"
                v-if="datosProceso.MANEJADOR == 'IATA_MENSAJE'"
              >
                <v-text-field
                  class="mt-0"
                  label="Email Remitente"
                  v-model="datosProceso.MENSAJE_REMITENTE"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="py-0 my-0"
                v-if="datosProceso.MANEJADOR == 'IATA_MENSAJE'"
              >
                <v-textarea
                  class="mt-0"
                  rows="3"
                  label="Emails destinatarios"
                  v-model="datosProceso.MENSAJE_DESTINATARIO"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-toolbar flat>
            <v-btn @click="guardarProceso()" small color="green" dark
              ><v-icon left>mdi-content-save</v-icon>Guardar</v-btn
            >
            <v-divider vertical class="px-4"></v-divider>
            <v-btn @click="dialogProceso = false" small text
              ><v-icon left>mdi-cancel</v-icon>Cancelar</v-btn
            >
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<style>
.v-text-field input {
  font-size: 0.8em;
}

.v-text-field .v-label {
  font-size: 0.95em;
}
</style>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "AerolineasComponent",

  components: {},
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Aerolinea", value: "ENTE_NOMBRE" },
      { text: "Codigo", value: "ENTE_ID" },
      { text: "RUC", value: "ENTE_RUC" },
    ],

    headersContactos: [
      { text: "Opciones", value: "opcion" },
      { text: "Nombre", value: "NOMBRE" },
      { text: "Ocupacion", value: "OCUPACION" },
      { text: "Correo", value: "CORREO1" },
      { text: "Teléfono", value: "TELEFONO1" },
      { text: "Celular", value: "CELULAR1" },
    ],

    headersProcesos: [
      { text: "Opciones", value: "opcion" },
      { text: "Descripción", value: "NOMBRE" },
      { text: "Metodo", value: "MANEJADOR" },
      { text: "Formato", value: "FORMATO" },
    ],
    metodos: [
      { nombre: "CORREO", codigo: "IATA_MENSAJE" },
      { nombre: "MENSAJERIA DESCARTES", codigo: "MSJ_CARGOIMP" },
      { nombre: "ARCHIVO PLANO OPD", codigo: "OPD_TAMPA" },
    ],
    formatos: [
      { nombre: "FWB16 / FHL4", codigo: "FWB16_FHL4" },
      { nombre: "FWB17 / FHL5", codigo: "FWB17_FHL5" },
    ],
    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRule: [
      (v) =>
        String(v)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || "El email es inválido",
    ],
    lista: [],
    options: {},
    notifications: false,
    paises: [],

    busqueda: null,
    formDaeTitulo: "",

    itemsPerPage: 20,
    pageCount: 1,
    totalItems: 0,
    currentPage: 1,
    tabAerolinea: 0,
    dialogAerolinea: false,

    formTitulo: "Aerolinea",

    incInac: false,
    datosAerolinea: { ENTE_ID: "", ENTE_EMAIL1: "", TIPO_ENTE_ID: 2 },
    listaContactos: [],

    lectura: false,

    show: false,
    show2: false,

    timeout: 5000,

    dialogContacto: false,
    datosContacto: {},

    sbAerolinea: false,
    tabAl: true,
    tabProc: true,
    tabContactos: true,

    mensajeSbAerolinea: "",

    procesos: [],
    dialogProceso: false,
    datosProceso: {
      AEROLINEA_ID: "",
      MANEJADOR: "",
      MENSAJE_REMITENTE: "",
      MENSAJE_DESTINATARIO: "",
      ID: "",
      NOMBRE: "",
      FORMATO: "",
    },
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "months"]),

    ...mapGetters("access", []),
    validarEmail1() {
      return this.validarEmail(this.datosAerolinea.ENTE_EMAIL1);
    },
    validarEmail1Contacto() {
      return this.validarEmail(this.datosContacto.CORREO1);
    },
    validarEmail2Contacto() {
      return this.validarEmail(this.datosContacto.CORREO2);
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),

    validarEmail(email) {
      if (email == "") {
        return [true];
      }
      if (email == null) {
        return [true];
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },
    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-aerolineas");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          sortBy: this.options.sortBy[0],
          sortDesc: this.options.sortDesc[0],
          inactivos: this.incInac,
          bus_nombre_aerolinea: this.busqueda,
        },
      })
        .then((res) => {
          this.lista = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
          console.log(this.totalItems);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    abrirDialogAerolinea(al) {
      this.tabAerolinea = 0;
      this.dialogAerolinea = true;
      this.tabContactos = true;
      this.tabProc = true;
      if (al == null) {
        this.tabContactos = false;
        this.tabProc = false;
        this.cargarDatosAerolinea(null);
        this.datosAerolinea = { ENTE_ID: null, TIPO_ENTE_ID: 2, ACTIVO: 1 };
        return false;
      }
      this.cargarDatosAerolinea(al.ENTE_ID);
    },
    cargarDatosAerolinea(aerolineaId) {
      this.setLoadingTable(true);
      this.setUrl("api/aerolinea");
      this.requestApi({
        method: "GET",
        data: {
          aerolinea_id: aerolineaId == null ? "" : aerolineaId,
        },
      })
        .then((res) => {
          if (aerolineaId != null) {
            this.datosAerolinea = res.data.datosAerolinea;
            this.listaContactos = res.data.contactos;
            this.procesos = res.data.procesos;
          }
          this.paises = res.data.paises;
          this.formTitulo = "Aerolinea: " + this.datosAerolinea.ENTE_NOM_COMER;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    modificarAerolinea() {
      this.setUrl("api/modificar-aerolinea");
      this.requestApi({
        method: "POST",
        data: {
          aerolinea: this.datosAerolinea,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.dialogAerolinea = false;
          this.cargarLista();
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarAerolinea() {
      if (!this.$refs.formAerolinea.validate()) {
        return false;
      }
      //console.log(this.datosAerolinea);
      if (this.datosAerolinea.ENTE_ID == null) {
        this.crearAerolinea();
      } else {
        this.modificarAerolinea();
      }
    },
    crearAerolinea() {
      this.setUrl("api/crear-aerolinea");
      //console.log(this.datosAerolinea);
      this.requestApi({
        method: "POST",
        data: {
          aerolinea: this.datosAerolinea,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargarLista();
          this.dialogAerolinea = false;
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    handlePageChange() {
      this.cargarLista();
    },
    abrirDialogContacto(item) {
      if (item === null) {
        this.datosContacto = {
          CONTACTO_ID: "",
          TIPO_ENTE_ID: 1,
          CLIENTE_ID: this.datosAerolinea.ENTE_ID,
        };
        this.dialogContacto = true;
        return false;
      }
      this.dialogContacto = true;
      this.setUrl("api/contacto-al");
      this.requestApi({
        method: "GET",
        data: {
          contacto_id: item.CONTACTO_ID,
        },
      })
        .then((res) => {
          this.datosContacto = res.data.datosContacto;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    crearCotacto() {
      if (!this.$refs.formContacto.validate()) {
        return false;
      }
      this.dialogContacto = true;
      this.setUrl("api/crear-contacto-al");
      this.requestApi({
        method: "POST",
        data: {
          contacto: this.datosContacto,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargarDatosAerolinea(this.datosAerolinea.ENTE_ID);
          this.dialogContacto = false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    modificarContacto() {
      if (!this.$refs.formContacto.validate()) {
        return false;
      }
      this.dialogContacto = true;
      this.setUrl("api/modificar-contacto-al");
      this.requestApi({
        method: "POST",
        data: {
          contacto: this.datosContacto,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargarDatosAerolinea(this.datosAerolinea.ENTE_ID);
          this.dialogContacto = false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    borrarContacto(contactoId) {
      Vue.swal({
        html: "Está seguro de borrar este contacto  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-contacto-al");
          this.requestApi({
            method: "POST",
            data: {
              contactoId: contactoId,
            },
          })
            .then((res) => {
              this.alertNotification({
                param: {
                  html: res.data.msg,
                  timer: 3000,
                },
              });
              this.cargarDatosAerolinea(this.datosAerolinea.ENTE_ID);
            })
            .catch(() => {})
            .then(() => {});
        }
      });
    },
    guardarContacto() {
      if (this.datosContacto.CONTACTO_ID != "") {
        this.modificarContacto();
      } else {
        this.crearCotacto();
      }
    },

    //PROCESOS
    abrirDialogProceso(item) {
      if (item === null) {
        this.datosProceso = {
          ID: "",
          MANEJADOR: "",
          AEROLINEA_ID: this.datosAerolinea.ENTE_ID,
          FORMATO: "",
          NOMBRE: "",
          MENSAJE_REMITENTE: "",
          MENSAJE_DESTINATARIO: "",
        };
        this.dialogProceso = true;
        return false;
      }
      this.dialogProceso = true;
      this.setUrl("api/proceso-al");
      this.requestApi({
        method: "GET",
        data: {
          proceso_id: item.ID,
        },
      })
        .then((res) => {
          this.datosProceso = res.data.datosProceso;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    crearProceso() {
      if (!this.$refs.formProceso.validate()) {
        return false;
      }
      this.dialogProceso = true;
      this.setUrl("api/crear-proceso-al");
      this.requestApi({
        method: "POST",
        data: {
          proceso: this.datosProceso,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargarDatosAerolinea(this.datosAerolinea.ENTE_ID);
          this.dialogProceso = false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    modificarProceso() {
      if (!this.$refs.formProceso.validate()) {
        return false;
      }
      this.dialogProceso = true;
      this.setUrl("api/modificar-proceso-al");
      this.requestApi({
        method: "POST",
        data: {
          proceso: this.datosProceso,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargarDatosAerolinea(this.datosAerolinea.ENTE_ID);
          this.dialogProceso = false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    borrarProceso(procesoId) {
      Vue.swal({
        html: "Está seguro de borrar este dato  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-proceso-al");
          this.requestApi({
            method: "POST",
            data: {
              procesoId: procesoId,
            },
          })
            .then((res) => {
              this.alertNotification({
                param: {
                  html: res.data.msg,
                  timer: 3000,
                },
              });
              this.cargarDatosAerolinea(this.datosAerolinea.ENTE_ID);
            })
            .catch(() => {})
            .then(() => {});
        }
      });
    },
    guardarProceso() {
      if (this.datosProceso.ID != "") {
        this.modificarProceso();
      } else {
        this.crearProceso();
      }
    },
    setCodigoPais() {
      this.datosAerolinea.ENTE_PAIS_COD = this.datosAerolinea.ENTE_PAIS;
    },
    borrarAerolinea(aerolineaId) {
      Vue.swal({
        html: "Está seguro de borrar esta aerolinea  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-aerolinea");
          this.requestApi({
            method: "POST",
            data: {
              aerolineaId: aerolineaId,
            },
          })
            .then((res) => {
              this.alertNotification({
                param: {
                  html: res.data.msg,
                  timer: 3000,
                },
              });
              this.cargarLista();
            })
            .catch(() => {})
            .then(() => {});
        }
      });
    },
  },
  mounted() {
    //this.$vuetify.lang.current = 'es'
    //this.cargarLista()
    document.title = "Sistema de carga";
    this.setTitleToolbar("Aerolineas");
  },
  watch: {
    options: {
      handler() {
        this.cargarLista();
      },
      deep: true,
    },
  },
};
</script>
